import React, { useState } from 'react';
import './financial.css';

export const LabeledInput = ({ label, value, onChange, min, max, type = "number" }) => {
    const [inputValue, setInputValue] = useState(value.toString());

    const handleChange = (e) => {
        const newValue = e.target.value;

        // Allow empty string or valid numbers
        if (newValue === '' || newValue === '-') {
            setInputValue(newValue);
            onChange(0); // Keep the parent state as 0 when empty
        } else {
            const numberValue = Number(newValue);
            if (!isNaN(numberValue)) {
                setInputValue(newValue);
                onChange(numberValue);
            }
        }
    };

    // Update local input value when parent value changes
    if (value.toString() !== inputValue && document.activeElement !== document.getElementById(label)) {
        setInputValue(value.toString());
    }

    return (
        <div className="input-group">
            <label className="input-label">{label}</label>
            <input
                id={label}
                type="text" // Changed from "number" to "text"
                inputMode="numeric" // This brings up the number keyboard on mobile
                value={inputValue}
                onChange={handleChange}
                min={min}
                max={max}
                className="input"
            />
        </div>
    );
};

export const LabeledSwitch = ({ label, checked, onChange }) => (
    <div className="switch-container">
        <label className="switch">
            <input
                type="checkbox"
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
            />
            <span className="switch-slider"></span>
        </label>
        <span className="input-label">{label}</span>
    </div>
);