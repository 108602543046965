import React from 'react';
import { LabeledInput, LabeledSwitch } from './LabeledInputs';
import './financial.css';

const TaxableInvestment = ({
    useTaxableAccount,
    setUseTaxableAccount,
    monthlyContribution,
    setMonthlyContribution,
    initialBalance,
    setInitialBalance,
    investmentReturn,
    setInvestmentReturn,
    dividendYield,
    setDividendYield,
    capitalGainsRate,
    setCapitalGainsRate,
    dividendTaxRate,
}) => (
    <div className="taxable-investment-account-section">
        <h3 className="section-title">TAXABLE INVESTMENT ACCOUNT</h3>
        <div className="grid grid-2">
            <LabeledSwitch
                label="Enable Taxable Investment Account"
                checked={useTaxableAccount}
                onChange={setUseTaxableAccount}
            />
        </div>

        {useTaxableAccount && (
            <>
                <div className="grid grid-3">
                    <LabeledInput
                        label="Initial Balance ($)"
                        value={initialBalance}
                        onChange={setInitialBalance}
                        min={0}
                    />
                    <LabeledInput
                        label="Monthly Contribution ($)"
                        value={monthlyContribution}
                        onChange={setMonthlyContribution}
                        min={0}
                    />
                    <LabeledInput
                        label="Expected Return (%)"
                        value={investmentReturn}
                        onChange={setInvestmentReturn}
                        min={-100}
                        max={100}
                    />
                </div>
                <div className="grid grid-3">
                    <LabeledInput
                        label="Dividend Yield (%)"
                        value={dividendYield}
                        onChange={setDividendYield}
                        min={0}
                        max={100}
                    />
                    <LabeledInput
                        label="Dividend Tax Rate (%)"
                        value={dividendTaxRate}
                        min={0}
                        max={100}
                        disabled={true}
                    />
                    <LabeledInput
                        label="Capital Gains Tax Rate (%)"
                        value={capitalGainsRate}
                        onChange={setCapitalGainsRate}
                        min={0}
                        max={100}
                    />
                </div>
            </>
        )}
    </div>
);

export default TaxableInvestment;