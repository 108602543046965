import React, { useState, useMemo, useEffect } from 'react';
import { LabeledInput } from './LabeledInputs';
import './taxes.css';

const STATE_TAX_RATES = {
    'CA': { rate: 9.3, name: 'California' },
    'NY': { rate: 6.85, name: 'New York' },
    'TX': { rate: 0, name: 'Texas' },
    'FL': { rate: 0, name: 'Florida' },
};

const TaxesSection = ({ income, filingStatus, setFilingStatus, dependents, setDependents, onTaxesChange }) => {
    // Load initial values from localStorage
    const getStoredValue = (key, defaultValue) => {
        const stored = localStorage.getItem(key);
        return stored ? JSON.parse(stored) : defaultValue;
    };

    const [selectedState, setSelectedState] = useState(getStoredValue('selectedState', 'CA'));
    const [customTaxes, setCustomTaxes] = useState(getStoredValue('customTaxes', {}));
    const [newTaxName, setNewTaxName] = useState('');

    const federalTaxRate = {
        base: 0.10,
        bracket1: 0.12,
        bracket2: 0.22,
        bracket3: 0.24,
        bracket4: 0.32,
        bracket5: 0.35,
        bracket6: 0.37
    };

    const ficaRates = {
        socialSecurity: 0.062, // Employee portion of 12.4% total
        medicare: 0.0145, // Employee portion of 2.9% total
        additionalMedicare: {
            rate: 0.009, // Additional 0.9% Medicare tax
            threshold: 200000 // Threshold for additional Medicare tax
        },
        socialSecurityWageLimit: 176100 // 2025 wage base limit
    };

    // Save to localStorage whenever values change
    useEffect(() => {
        localStorage.setItem('selectedState', JSON.stringify(selectedState));
        localStorage.setItem('customTaxes', JSON.stringify(customTaxes));
    }, [selectedState, customTaxes]);

    // Calculate standard deduction and dependent credits
    const standardDeduction = useMemo(() => {
        switch (filingStatus) {
            case 'married':
                return 29200;
            case 'head':
                return 21900;
            default:
                return 14600;
        }
    }, [filingStatus]);

    const dependentCredit = useMemo(() => {
        return dependents * 2000; // $2000 credit per dependent
    }, [dependents]);

    // Calculate federal tax (simplified)
    const federalTax = useMemo(() => {
        const taxableIncome = Math.max(income - standardDeduction, 0);
        let tax = 0;

        if (taxableIncome <= 11600) tax = taxableIncome * federalTaxRate.base;
        else if (taxableIncome <= 47150) tax = 1160 + (taxableIncome - 11600) * federalTaxRate.bracket1;
        else if (taxableIncome <= 100525) tax = 5426 + (taxableIncome - 47150) * federalTaxRate.bracket2;
        else if (taxableIncome <= 191950) tax = 17168.50 + (taxableIncome - 100525) * federalTaxRate.bracket3;
        else if (taxableIncome <= 243725) tax = 39110.50 + (taxableIncome - 191950) * federalTaxRate.bracket4;
        else if (taxableIncome <= 609350) tax = 55678.50 + (taxableIncome - 243725) * federalTaxRate.bracket5;
        else tax = 183647.25 + (taxableIncome - 609350) * federalTaxRate.bracket6;

        return Math.max(tax - dependentCredit, 0); // Apply dependent credits
    }, [income, standardDeduction, dependentCredit, federalTaxRate.base, federalTaxRate.bracket1, federalTaxRate.bracket2, federalTaxRate.bracket3, federalTaxRate.bracket4, federalTaxRate.bracket5, federalTaxRate.bracket6]);

    // Calculate state tax
    const stateTax = useMemo(() => {
        const rate = STATE_TAX_RATES[selectedState]?.rate || 0;
        return (income * rate) / 100;
    }, [income, selectedState]);

    // Calculate FICA taxes
    const ficaTaxes = useMemo(() => {
        const socialSecurity = Math.min(income * ficaRates.socialSecurity, ficaRates.socialSecurityWageLimit * ficaRates.socialSecurity);
        const medicare = income * ficaRates.medicare;
        const additionalMedicare = income > ficaRates.additionalMedicare.threshold ?
            (income - ficaRates.additionalMedicare.threshold) * ficaRates.additionalMedicare.rate : 0;
        return {
            socialSecurity,
            medicare,
            additionalMedicare
        };
    }, [income, ficaRates.socialSecurity, ficaRates.socialSecurityWageLimit, ficaRates.medicare, ficaRates.additionalMedicare.threshold, ficaRates.additionalMedicare.rate]);

    const addNewTax = () => {
        if (newTaxName.trim()) {
            const formattedName = newTaxName.trim()
                .toLowerCase()
                .replace(/\s+/g, '_');

            if (!customTaxes[formattedName]) {
                setCustomTaxes({
                    ...customTaxes,
                    [formattedName]: 0
                });
                setNewTaxName('');
            }
        }
    };

    const removeTax = (taxName) => {
        const updatedTaxes = { ...customTaxes };
        delete updatedTaxes[taxName];
        setCustomTaxes(updatedTaxes);
    };

    const totalTaxes = useMemo(() => {
        const customTotal = Object.values(customTaxes).reduce((sum, amount) => sum + amount, 0);
        return federalTax + stateTax + ficaTaxes.socialSecurity + ficaTaxes.medicare + ficaTaxes.additionalMedicare + customTotal;
    }, [federalTax, stateTax, ficaTaxes, customTaxes]);

    // Move tax data update to useEffect to avoid setState during render
    useEffect(() => {
        const customTotal = Object.values(customTaxes).reduce((sum, amount) => sum + amount, 0);
        onTaxesChange({
            total: totalTaxes,
            federal: federalTax,
            state: stateTax,
            fica: ficaTaxes.socialSecurity + ficaTaxes.medicare + ficaTaxes.additionalMedicare,
            custom: customTotal,
            effectiveRate: (totalTaxes / income) * 100,
            dependentCredit: dependentCredit
        });
    }, [totalTaxes, federalTax, stateTax, ficaTaxes, customTaxes, income, onTaxesChange, dependentCredit]);

    const defaultTaxes = [
    ];

    const summaryItems = [
        { key: 'standard_deduction', label: `Standard Deduction (${filingStatus})`, value: -standardDeduction },
        { key: 'dependent_credits', label: `Dependent Credits (${dependents} x $2,000)`, value: -dependentCredit },
        { key: 'federal_tax', label: `Federal Tax (${((federalTax / income) * 100).toFixed(1)}%)`, value: federalTax },
        { key: 'state_tax', label: `State Tax (${STATE_TAX_RATES[selectedState]?.name}) (${STATE_TAX_RATES[selectedState]?.rate}%)`, value: stateTax },
        { key: 'social_security', label: `Social Security (${(ficaRates.socialSecurity * 100).toFixed(1)}%)`, value: ficaTaxes.socialSecurity },
        { key: 'medicare', label: `Medicare (${(ficaRates.medicare * 100).toFixed(1)}%)`, value: ficaTaxes.medicare },
        { key: 'additional_medicare', label: `Additional Medicare (${(ficaRates.additionalMedicare.rate * 100).toFixed(1)}%)`, value: ficaTaxes.additionalMedicare },
        ...Object.entries(customTaxes).map(([key, value]) => ({
            key,
            label: `${key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} (${((value / income) * 100).toFixed(1)}%)`,
            value
        })),
        { key: 'total_annual', label: 'Total Annual Taxes', value: totalTaxes },
        { key: 'monthly_amount', label: 'Monthly Tax Amount', value: totalTaxes / 12 },
        { key: 'effective_rate', label: 'Effective Tax Rate', value: ((totalTaxes / income) * 100).toFixed(1), isPercentage: true }
    ];

    return (
        <div className="tax-section">
            <h2 className="section-title">TAX ESTIMATES</h2>

            <div className="tax-inputs-grid">
                <div className="tax-input-container">
                    <label>State</label>
                    <select
                        value={selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                        className="tax-select"
                    >
                        {Object.entries(STATE_TAX_RATES).map(([code, { name, rate }]) => (
                            <option key={code} value={code}>{name} ({rate}%)</option>
                        ))}
                    </select>
                </div>

                <div className="tax-input-container">
                    <label>Filing Status</label>
                    <select
                        value={filingStatus}
                        onChange={(e) => setFilingStatus(e.target.value)}
                        className="tax-select"
                    >
                        <option value="single">Single</option>
                        <option value="married">Married Filing Jointly</option>
                        <option value="head">Head of Household</option>
                    </select>
                </div>

                <div className="tax-input-container">
                    <LabeledInput
                        label="Number of Dependents"
                        value={dependents}
                        onChange={setDependents}
                        type="number"
                    />
                </div>
            </div>

            <div className="grid grid-3">
                {defaultTaxes.map(({ key, label, value }) => (
                    <div key={key} className="expense-input-container">
                        <LabeledInput
                            label={label}
                            value={value}
                            onChange={() => { }}
                            disabled={true}
                        />
                    </div>
                ))}
                {Object.entries(customTaxes).map(([key, value]) => (
                    <div key={key} className="expense-input-container">
                        <LabeledInput
                            label={key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            value={value}
                            onChange={(newValue) => setCustomTaxes({ ...customTaxes, [key]: newValue })}
                        />
                        <button
                            className="remove-expense-btn"
                            onClick={() => removeTax(key)}
                            title="Remove this tax"
                        >
                            ×
                        </button>
                    </div>
                ))}
            </div>

            <div className="add-expense-container">
                <input
                    type="text"
                    value={newTaxName}
                    onChange={(e) => setNewTaxName(e.target.value)}
                    placeholder="New tax/refund name..."
                    className="new-expense-input"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && newTaxName.trim()) {
                            addNewTax();
                        }
                    }}
                />
                <button
                    onClick={addNewTax}
                    className="add-expense-btn"
                    disabled={!newTaxName.trim()}
                >
                    Add Tax/Refund
                </button>
            </div>

            <div className="tax-summary">
                {summaryItems.map(item => (
                    <div key={item.key} className="summary-item">
                        <span className="tax-label">{item.label}:</span>
                        <span className="tax-amount">
                            {item.isPercentage ?
                                `${item.value}%` :
                                `$${item.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                            }
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TaxesSection;
