import React, { useState, useEffect } from 'react';
import { LabeledInput } from './LabeledInputs';
import './expenses.css';

const ExpenseSection = ({
    expenses,
    setExpenses,
    totalExpenses,
    monthlyIncomeAfterExpenses,
    monthlyTaxes
}) => {
    // Load cached expense name from localStorage
    const [newExpenseName, setNewExpenseName] = useState(() => {
        const cached = localStorage.getItem('newExpenseName');
        return cached ? JSON.parse(cached) : '';
    });

    // Cache newExpenseName whenever it changes
    useEffect(() => {
        localStorage.setItem('newExpenseName', JSON.stringify(newExpenseName));
    }, [newExpenseName]);

    const addNewExpense = () => {
        if (newExpenseName.trim()) {
            const formattedName = newExpenseName.trim()
                .toLowerCase()
                .replace(/\s+/g, '_');

            if (!expenses[formattedName]) {
                setExpenses({
                    ...expenses,
                    [formattedName]: 0
                });
                setNewExpenseName('');
            }
        }
    };

    const removeExpense = (expenseName) => {
        const updatedExpenses = { ...expenses };
        delete updatedExpenses[expenseName];
        setExpenses(updatedExpenses);
    };

    const defaultExpenses = [
        { key: 'housing', label: 'Housing (Rent/Mortgage)' },
        { key: 'bills', label: 'Bills' },
        { key: 'food', label: 'Food & Groceries' },
        { key: 'transportation', label: 'Transportation' },
        { key: 'healthcare', label: 'Healthcare' },
        { key: 'insurance', label: 'Insurance' },
        { key: 'entertainment', label: 'Entertainment' },
        { key: 'personalCare', label: 'Personal Care' },
        { key: 'other', label: 'Other' }
    ];

    const customExpenses = Object.keys(expenses)
        .filter(key => !defaultExpenses.find(def => def.key === key))
        .map(key => ({
            key,
            label: key.split('_').map(word =>
                word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' ')
        }));

    return (
        <div className="expenses-section">
            <h2 className="section-title">MONTHLY EXPENSES</h2>

            {monthlyIncomeAfterExpenses < 0 && (
                <div className="warning">
                    Warning: Your monthly expenses exceed your income by ${Math.abs(monthlyIncomeAfterExpenses).toLocaleString()}
                </div>
            )}

            <div className="grid grid-3">
                {[...defaultExpenses, ...customExpenses].map(({ key, label }) => (
                    <div key={key} className="expense-input-container">
                        <LabeledInput
                            label={label}
                            value={expenses[key]}
                            onChange={(value) => setExpenses({ ...expenses, [key]: value })}
                        />
                        {!defaultExpenses.find(def => def.key === key) && (
                            <button
                                className="remove-expense-btn"
                                onClick={() => removeExpense(key)}
                                title="Remove this expense"
                            >
                                ×
                            </button>
                        )}
                    </div>
                ))}
            </div>

            <div className="add-expense-container">
                <input
                    type="text"
                    value={newExpenseName}
                    onChange={(e) => setNewExpenseName(e.target.value)}
                    placeholder="New expense name..."
                    className="new-expense-input"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && newExpenseName.trim()) {
                            addNewExpense();
                        }
                    }}
                />
                <button
                    onClick={addNewExpense}
                    className="add-expense-btn"
                    disabled={!newExpenseName.trim()}
                >
                    Add Expense
                </button>
            </div>

            <div className="expense-summary">
                <div className="summary-item">
                    <span className="summary-label">Monthly Taxes:</span>
                    <span className="summary-value">${monthlyTaxes.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                </div>
                <div className="summary-item">
                    <span className="summary-label">Total Monthly Expenses:</span>
                    <span className="summary-value">${totalExpenses.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                </div>
                <div className="summary-item">
                    <span className="summary-label">Monthly Income After Taxes & Expenses:</span>
                    <span className="summary-value" style={{ color: monthlyIncomeAfterExpenses < 0 ? '#ff0000' : 'inherit' }}>
                        ${monthlyIncomeAfterExpenses.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                </div>
                <div className="summary-item">
                    <span className="summary-label">Annual Taxes:</span>
                    <span className="summary-value">${(monthlyTaxes * 12).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                </div>
                <div className="summary-item">
                    <span className="summary-label">Annual Expenses:</span>
                    <span className="summary-value">${(totalExpenses * 12).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                </div>
                <div className="summary-item">
                    <span className="summary-label">Annual Income Saved:</span>
                    <span className="summary-value" style={{ color: monthlyIncomeAfterExpenses < 0 ? '#ff0000' : 'inherit' }}>${(monthlyIncomeAfterExpenses * 12).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                </div>
            </div>
        </div>
    );
};

export default ExpenseSection;