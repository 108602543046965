import React, { useState, useEffect } from 'react';
import { LabeledInput } from './LabeledInputs';
import './yearly_breakdown.css';

const YearlyBreakdown = ({
  showYearlyBreakdown,
  setShowYearlyBreakdown,
  projectionData,
  inflationRate,
  yearlyInflationRates,
  setYearlyInflationRates,
  recalculateProjections
}) => {
  // Load cached values from localStorage
  const [cachedShowBreakdown, setCachedShowBreakdown] = useState(() => {
    const cached = localStorage.getItem('showYearlyBreakdown');
    return cached ? JSON.parse(cached) : showYearlyBreakdown;
  });

  const [cachedYearlyRates, setCachedYearlyRates] = useState(() => {
    const cached = localStorage.getItem('yearlyInflationRates');
    return cached ? JSON.parse(cached) : yearlyInflationRates;
  });

  // Update cache when values change
  useEffect(() => {
    localStorage.setItem('showYearlyBreakdown', JSON.stringify(cachedShowBreakdown));
    setShowYearlyBreakdown(cachedShowBreakdown);
  }, [cachedShowBreakdown, setShowYearlyBreakdown]);

  useEffect(() => {
    localStorage.setItem('yearlyInflationRates', JSON.stringify(cachedYearlyRates));
    setYearlyInflationRates(cachedYearlyRates);
  }, [cachedYearlyRates, setYearlyInflationRates]);

  const handleInflationChange = (year, value) => {
    const newRates = {
      ...cachedYearlyRates,
      [year]: value
    };
    setCachedYearlyRates(newRates);
    recalculateProjections(newRates);
  };

  const formatCurrency = (value) => {
    if (value == null) return '$0.00';
    return `$${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  return (
    <div className="yearly-breakdown-section">
      <div
        className="collapsible-header"
        onClick={() => setCachedShowBreakdown(!cachedShowBreakdown)}
      >
        <h3 className="section-title">YEARLY BREAKDOWN</h3>
        <span>{cachedShowBreakdown ? '▼' : '▶'}</span>
      </div>
      <div className={`collapsible-content ${cachedShowBreakdown ? 'open' : ''}`}>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Year</th>
                <th>Age</th>
                <th className="text-right">Income (Quarter of Inflation)</th>
                <th className="text-right">Income Tax</th>
                <th className="text-right">Expenses / Withdrawals</th>
                <th className="text-right">Net Income</th>
                <th className="text-right">Annual Investment</th>
                <th className="text-right">All Investment Accounts</th>
                <th className="text-right">Cost Basis</th>
                <th className="text-right">Dividend Income</th>
                <th className="text-right">Dividend Tax</th>
                <th className="text-right">Unrealized Gains</th>
                <th className="text-right">Realized Gains</th>
                <th className="text-right">Capital Gain Withdrawal</th>
                <th className="text-right">Capital Gains Tax</th>
                <th className="text-right">Total Income</th>
                <th>
                  <div className="inflation-header">
                    <span>Inflation (%)</span>
                    <span className="inflation-default">Default: {inflationRate.toFixed(2)}%</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {projectionData.map((row) => (
                <tr key={row.year}>
                  <td>{row.year}</td>
                  <td>{row.age}</td>
                  <td className="text-right" style={{ color: 'green' }}>{formatCurrency(row.income)}</td>
                  <td className="text-right" style={{ color: 'red' }}>{formatCurrency(row.totalTax)}</td>
                  <td className="text-right" style={{ color: 'red' }}>{formatCurrency(row.expenses)}</td>
                  <td className="text-right" style={{ color: 'green' }}>{formatCurrency(row.netIncome)}</td>
                  <td className="text-right">{formatCurrency(row.taxableContribution)}</td>
                  <td className="text-right">{formatCurrency(row.totalBalance)}</td>
                  <td className="text-right">{formatCurrency(row.costBasis)}</td>
                  <td className="text-right" style={{ color: 'green' }}>{formatCurrency(row.dividendIncome)}</td>
                  <td className="text-right" style={{ color: 'red' }}>{formatCurrency(row.dividendTax)}</td>
                  <td className="text-right" style={{ color: 'green' }}>{formatCurrency(row.unrealizedCapitalGains)}</td>
                  <td className="text-right" style={{ color: 'green' }}>{formatCurrency(row.realizedCapitalGains)}</td>
                  <td className="text-right" style={{ color: 'red' }}>{formatCurrency(row.capitalGainWithdrawal)}</td>
                  <td className="text-right" style={{ color: 'red' }}>{formatCurrency(row.capitalGainsTax)}</td>
                  <td className="text-right" style={{ color: 'green' }}>{formatCurrency(row.totalIncome)}</td>
                  <td className="inflation-cell">
                    <LabeledInput
                      value={cachedYearlyRates[row.year] ?? inflationRate}
                      onChange={(value) => handleInflationChange(row.year, value)}
                      min={-20}
                      max={100}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default YearlyBreakdown;