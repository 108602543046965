// Tax Constants
export const TAX_CONSTANTS = {
    CURRENT_YEAR: new Date().getFullYear(),
    MAX_AGE: 120,
    REGULAR_401K_LIMIT: 23000,
    CATCHUP_401K_AMOUNT: 7500,
    REGULAR_IRA_LIMIT: 6500,
    CATCHUP_IRA_AMOUNT: 1000,
};

// Add this function to your utils.js
export const get401kLimit = (age) => {
    // If age 50 or older, include catch-up contribution amount
    if (age >= 50) {
        return TAX_CONSTANTS.REGULAR_401K_LIMIT + TAX_CONSTANTS.CATCHUP_401K_AMOUNT;
    }

    // Under 50, return regular limit
    return TAX_CONSTANTS.REGULAR_401K_LIMIT;
};

export const getIRALimit = (age, income, filingStatus, isRothIRA) => {
    const baseLimit = age >= 50 ?
        TAX_CONSTANTS.REGULAR_IRA_LIMIT + TAX_CONSTANTS.CATCHUP_IRA_AMOUNT :
        TAX_CONSTANTS.REGULAR_IRA_LIMIT;

    if (isRothIRA) {
        const phaseoutStart = filingStatus === 'married' ? 218000 : 146000;
        const phaseoutEnd = filingStatus === 'married' ? 228000 : 161000;

        if (income >= phaseoutEnd) return 0;
        if (income > phaseoutStart) {
            const reduction = (income - phaseoutStart) / (phaseoutEnd - phaseoutStart);
            return Math.round(baseLimit * (1 - reduction));
        }
    }

    return baseLimit;
};

export const calculateEmployerMatch = (contribution, income, employerMatch) => {
    const { firstTierMatch, firstTierLimit, secondTierMatch, secondTierLimit } = employerMatch;

    const firstTierAmount = Math.min(
        income * (firstTierLimit / 100),
        contribution
    ) * (firstTierMatch / 100);

    const remainingContribution = Math.max(
        0,
        contribution - (income * (firstTierLimit / 100))
    );

    const secondTierAmount = Math.min(
        income * (secondTierLimit / 100),
        remainingContribution
    ) * (secondTierMatch / 100);

    return firstTierAmount + secondTierAmount;
};