import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LabeledInput } from './LabeledInputs';
import YearlyBreakdown from './YearlyBreakdown';
import ProjectionChart from './ProjectionChart';
import {
  TAX_CONSTANTS,
} from './utils';
import './financial.css';
import ExpenseSection from './ExpenseSection';
import TaxesSection from './TaxesSection';
import TaxableInvestment from './TaxableInvestment';

const MainFinancial = () => {

  // Load initial values from localStorage or use defaults
  const getStoredValue = (key, defaultValue) => {
    const stored = localStorage.getItem(key);
    return stored ? JSON.parse(stored) : defaultValue;
  };
  const navigate = useNavigate();


  // Basic Information
  const [income, setIncome] = useState(getStoredValue('income', 100000));
  const [age, setAge] = useState(getStoredValue('age', 30));
  const [retirementAge, setRetirementAge] = useState(getStoredValue('retirementAge', 65));
  const [retirementExpenses, setRetirementExpenses] = useState(getStoredValue('retirementExpenses', 80000));
  const [filingStatus, setFilingStatus] = useState(getStoredValue('filingStatus', 'single'));
  const [dependents, setDependents] = useState(getStoredValue('dependents', 0));

  // Investment Settings
  const [inflationRate] = useState(getStoredValue('inflationRate', 2));
  const [yearlyInflationRates, setYearlyInflationRates] = useState(getStoredValue('yearlyInflationRates', {}));
  // UI State
  const [showYearlyBreakdown, setShowYearlyBreakdown] = useState(getStoredValue('showYearlyBreakdown', true));

  // Expenses
  const [expenses, setExpenses] = useState(getStoredValue('expenses', {
    housing: 1500,
    bills: 200,
    food: 600,
    transportation: 400,
    healthcare: 300,
    insurance: 200,
    entertainment: 300,
    personalCare: 100,
    other: 200
  }));

  // Taxable Investment Account state
  const [useTaxableAccount, setUseTaxableAccount] = useState(getStoredValue('useTaxableAccount', true));
  const [taxableInitialBalance, setTaxableInitialBalance] = useState(getStoredValue('taxableInitialBalance', 5000));
  const [taxableMonthlyContribution, setTaxableMonthlyContribution] = useState(getStoredValue('taxableMonthlyContribution', 100));
  const [taxableInvestmentReturn, setTaxableInvestmentReturn] = useState(getStoredValue('taxableInvestmentReturn', 7));
  const [dividendYield, setDividendYield] = useState(getStoredValue('dividendYield', 2));
  const [capitalGainsRate, setCapitalGainsRate] = useState(getStoredValue('capitalGainsRate', 15));

  const [taxData, setTaxData] = useState(getStoredValue('taxData', {
    total: 0,
    federal: 0,
    state: 0,
    fica: 0,
    custom: 0,
    deductions: 0,
    effectiveRate: 0
  }));

  // Save to localStorage whenever values change
  useEffect(() => {
    localStorage.setItem('income', JSON.stringify(income));
    localStorage.setItem('age', JSON.stringify(age));
    localStorage.setItem('retirementAge', JSON.stringify(retirementAge));
    localStorage.setItem('retirementExpenses', JSON.stringify(retirementExpenses));
    localStorage.setItem('filingStatus', JSON.stringify(filingStatus));
    localStorage.setItem('dependents', JSON.stringify(dependents));
    localStorage.setItem('inflationRate', JSON.stringify(inflationRate));
    localStorage.setItem('yearlyInflationRates', JSON.stringify(yearlyInflationRates));
    localStorage.setItem('showYearlyBreakdown', JSON.stringify(showYearlyBreakdown));
    localStorage.setItem('expenses', JSON.stringify(expenses));
    localStorage.setItem('useTaxableAccount', JSON.stringify(useTaxableAccount));
    localStorage.setItem('taxableInitialBalance', JSON.stringify(taxableInitialBalance));
    localStorage.setItem('taxableMonthlyContribution', JSON.stringify(taxableMonthlyContribution));
    localStorage.setItem('taxableInvestmentReturn', JSON.stringify(taxableInvestmentReturn));
    localStorage.setItem('dividendYield', JSON.stringify(dividendYield));
    localStorage.setItem('capitalGainsRate', JSON.stringify(capitalGainsRate));
    localStorage.setItem('taxData', JSON.stringify(taxData));
  }, [
    income, age, retirementAge, retirementExpenses, filingStatus, dependents,
    inflationRate, yearlyInflationRates, showYearlyBreakdown, expenses,
    useTaxableAccount, taxableInitialBalance, taxableMonthlyContribution,
    taxableInvestmentReturn, dividendYield, capitalGainsRate, taxData
  ]);

  // Add these calculations
  const totalMonthlyExpenses = useMemo(() => {
    return Object.values(expenses).reduce((sum, expense) => sum + expense, 0);
  }, [expenses]);

  const monthlyIncomeAfterExpenses = useMemo(() => {
    const monthlyIncome = income / 12;
    const monthlyTaxes = taxData.total / 12;
    return monthlyIncome - totalMonthlyExpenses - monthlyTaxes;
  }, [income, totalMonthlyExpenses, taxData.total]);

  // Function to force recalculation of projections
  const recalculateProjections = (newInflationRates) => {
    // This will trigger the useMemo to recalculate
    setYearlyInflationRates(newInflationRates);
  };

  // Calculate dividend tax rate based on income and filing status
  const calculatedDividendTaxRate = useMemo(() => {
    if (filingStatus === 'married') {
      if (income <= 94050) return 0;
      else if (income <= 583750) return 15;
      else return 20;
    } else if (filingStatus === 'head') {
      if (income <= 63000) return 0;
      else if (income <= 551350) return 15;
      else return 20;
    } else { // single or separate
      if (income <= 47025) return 0;
      else if (income <= (filingStatus === 'single' ? 518900 : 291850)) return 15;
      else return 20;
    }
  }, [income, filingStatus]);

  const projectionData = useMemo(() => {
    const data = [];
    let taxableBalance = useTaxableAccount ? taxableInitialBalance : 0;
    let costBasis = taxableBalance;
    let currentYear = TAX_CONSTANTS.CURRENT_YEAR;
    let currentAge = age;
    let cumulativeInflation = 1;
    let currentIncome = income;

    const annualExpenses = totalMonthlyExpenses * 12;

    while (currentAge <= TAX_CONSTANTS.MAX_AGE) {
      const isRetired = currentAge >= retirementAge;
      const yearInflation = yearlyInflationRates[currentYear] ?? inflationRate;

      // Update cumulative inflation
      if (currentYear > TAX_CONSTANTS.CURRENT_YEAR) {
        cumulativeInflation *= (1 + yearInflation / 100);
        // Increase income by quarter of inflation rate
        if (!isRetired) {
          currentIncome *= (1 + (yearInflation / 400));
        }
      }

      // 1. Calculate year expenses
      const yearExpenses = (isRetired ? retirementExpenses : annualExpenses) * cumulativeInflation;

      // 2. Calculate income after taxes
      const yearlyIncome = isRetired ? 0 : currentIncome;
      const yearlyTotalTax = isRetired ? 0 : taxData.total;
      const netIncomeBeforeExpenses = yearlyIncome - yearlyTotalTax;

      let remainingExpenses = yearExpenses;
      let capitalGainWithdrawal = 0;
      let capitalGainsTax = 0;
      let realizedCapitalGains = 0;

      // Cover expenses with income if not retired
      if (!isRetired && netIncomeBeforeExpenses >= yearExpenses) {
        remainingExpenses = 0; // Expenses fully covered by income
      } else if (!isRetired) {
        remainingExpenses -= netIncomeBeforeExpenses; // Partially covered by income
      }

      // Withdraw from cost basis first (tax-free)
      if (remainingExpenses > 0 && taxableBalance > 0) {
        const costBasisWithdrawal = Math.min(costBasis, remainingExpenses);
        taxableBalance -= costBasisWithdrawal;
        costBasis -= costBasisWithdrawal;
        remainingExpenses -= costBasisWithdrawal;

        // Withdraw from capital gains if necessary
        if (remainingExpenses > 0) {
          capitalGainWithdrawal = Math.min(
            remainingExpenses / (1 - capitalGainsRate / 100),
            taxableBalance
          );
          capitalGainsTax = capitalGainWithdrawal * (capitalGainsRate / 100);
          realizedCapitalGains = capitalGainWithdrawal;

          taxableBalance -= (capitalGainWithdrawal + capitalGainsTax);
          remainingExpenses -= capitalGainWithdrawal;
        }
      }

      // 3. Calculate investment returns
      const annualReturn = taxableInvestmentReturn / 100;
      const investmentGrowth = taxableBalance * annualReturn;

      const dividendIncome = taxableBalance * (dividendYield / 100);
      const dividendTax = dividendIncome * (calculatedDividendTaxRate / 100);

      taxableBalance += investmentGrowth + dividendIncome - dividendTax;

      // 4. Add contributions if not retired
      let taxableContribution = 0;
      if (!isRetired && useTaxableAccount) {
        taxableContribution = taxableMonthlyContribution * 12;
        taxableBalance += taxableContribution;
        costBasis += taxableContribution;
      }

      // 5. Tally balances
      const totalBalance = Math.max(0, taxableBalance);
      const capitalGains = Math.max(0, taxableBalance - costBasis);
      const unrealizedCapitalGains = Math.max(0, capitalGains - realizedCapitalGains);

      const finalYearExpenses = yearExpenses;
      const totalIncome = yearlyIncome + dividendIncome + realizedCapitalGains;

      // Ensure all required values are present
      data.push({
        year: currentYear,
        age: currentAge,
        retirementAge,
        totalBalance,
        taxableContribution,
        expenses: finalYearExpenses,
        dividendIncome,
        dividendTax,
        costBasis,
        capitalGains,
        capitalGainsTax,
        capitalGainWithdrawal,
        realizedCapitalGains,
        unrealizedCapitalGains,
        inflationRate: yearInflation,
        taxableIncome: yearlyIncome,
        totalTax: yearlyTotalTax,
        income: yearlyIncome,
        netIncome: yearlyIncome - yearlyTotalTax - finalYearExpenses,
        totalIncome,
      });

      // Advance year
      currentYear++;
      currentAge++;
    }

    return data;
  }, [
    age, retirementAge, income, inflationRate, retirementExpenses,
    yearlyInflationRates, totalMonthlyExpenses, useTaxableAccount,
    taxableInitialBalance, taxableMonthlyContribution, taxableInvestmentReturn,
    dividendYield, capitalGainsRate, taxData, calculatedDividendTaxRate
  ]);


  // Handle tax data updates in an effect
  const [taxInputs, setTaxInputs] = useState({
    income,
    filingStatus,
    dependents
  });

  useEffect(() => {
    setTaxInputs({
      income,
      filingStatus,
      dependents
    });
  }, [income, filingStatus, dependents]);

  return (
    <div className="financial-planner">
      <div className="card">
        <div className="card-header">
          <button onClick={() => navigate('/')} className="back-button">←</button>
          <h1 className="card-title">Financial Dashboard</h1>
        </div>
        <div className="card-content">
          {/* Basic Information */}
          <div className="grid grid-4">
            <LabeledInput
              label="Annual Income ($)"
              value={income}
              onChange={setIncome}
            />
            <LabeledInput
              label="Current Age"
              value={age}
              onChange={setAge}
            />
            <LabeledInput
              label="Retirement Age"
              value={retirementAge}
              onChange={setRetirementAge}
            />
            <LabeledInput
              label="Retirement Expenses in Today's $"
              value={retirementExpenses}
              onChange={setRetirementExpenses}
            />
          </div>

          {/* Taxes Section */}
          <TaxesSection
            {...taxInputs}
            setFilingStatus={setFilingStatus}
            setDependents={setDependents}
            onTaxesChange={setTaxData}
          />

          {/* Expense Section */}
          <ExpenseSection
            expenses={expenses}
            setExpenses={setExpenses}
            totalExpenses={totalMonthlyExpenses}
            monthlyIncomeAfterExpenses={monthlyIncomeAfterExpenses}
            monthlyTaxes={taxData.total / 12}
          />

          {/* Taxable Investment Section */}
          <TaxableInvestment
            useTaxableAccount={useTaxableAccount}
            setUseTaxableAccount={setUseTaxableAccount}
            monthlyContribution={taxableMonthlyContribution}
            setMonthlyContribution={setTaxableMonthlyContribution}
            initialBalance={taxableInitialBalance}
            setInitialBalance={setTaxableInitialBalance}
            investmentReturn={taxableInvestmentReturn}
            setInvestmentReturn={setTaxableInvestmentReturn}
            dividendYield={dividendYield}
            setDividendYield={setDividendYield}
            capitalGainsRate={capitalGainsRate}
            setCapitalGainsRate={setCapitalGainsRate}
            dividendTaxRate={calculatedDividendTaxRate}
          />

          {/* Projection Chart */}
          <ProjectionChart projectionData={projectionData} />

          {/* Yearly Breakdown */}
          <YearlyBreakdown
            showYearlyBreakdown={showYearlyBreakdown}
            setShowYearlyBreakdown={setShowYearlyBreakdown}
            projectionData={projectionData}
            inflationRate={inflationRate}
            yearlyInflationRates={yearlyInflationRates}
            setYearlyInflationRates={setYearlyInflationRates}
            recalculateProjections={recalculateProjections}
          />

          <p>*All calculations are approximations and should not be used as financial advice.</p>

        </div>
      </div>
    </div>
  );
};

export default MainFinancial;