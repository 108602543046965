import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';
import FloatingChart from './FloatingChart';
import './financial.css';

const ProjectionChart = ({ projectionData }) => {
  // Early return if no data
  if (!projectionData || projectionData.length === 0) {
    return null;
  }

  // Get current year and retirement year from data
  const currentYear = projectionData[0].year;
  const retirementYear = currentYear + (projectionData[0].retirementAge - projectionData[0].age);

  // Format large numbers for tooltip and axis
  const formatCurrency = (value) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
      return `$${(value / 1000).toFixed(0)}k`;
    }
    return `$${value.toFixed(0)}`;
  };

  return (
    <FloatingChart title="Retirement Projection">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={projectionData}
          margin={{ left: 15 }} // Add left margin to prevent cutoff
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="year"
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 12 }} // Adjust font size
          />
          <YAxis
            tickFormatter={(value) => formatCurrency(value)}
            domain={[0, 'auto']}
            width={80} // Set fixed width for Y-axis
            tick={{ fontSize: 12 }} // Adjust font size
          />
          <Tooltip
            formatter={(value, name) => [formatCurrency(value), name]}
            labelFormatter={(label) => {
              const dataPoint = projectionData.find(d => d.year === label);
              return `Year: ${label} (Age: ${dataPoint.age})`;
            }}
          />
          <Legend />
          <ReferenceLine
            x={retirementYear}
            stroke="red"
            label="Retirement"
          />
          <Line
            type="monotone"
            dataKey="totalBalance"
            stroke="#2563eb"
            name="Total Balance"
            dot={false}
            strokeWidth={2}
          />
          {/* <Line
            type="monotone"
            dataKey="taxableBalance"
            stroke="#16a34a"
            name="Taxable Balance"
            dot={false}
            strokeWidth={2}
          /> */}
          <Line
            type="monotone"
            dataKey="expenses"
            stroke="#dc2626"
            name="Annual Expenses"
            dot={false}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </FloatingChart>
  );
};

export default ProjectionChart;