import React, { useState, useRef, useEffect, useCallback } from 'react';
import './floating-chart.css';

const FloatingChart = ({ children, title = "Retirement Projection" }) => {
    const [position, setPosition] = useState({ x: window.innerWidth - 620, y: window.innerHeight - 420 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [isMinimized, setIsMinimized] = useState(false);
    const containerRef = useRef(null);

    // Handle dragging
    const handleMouseDown = (e) => {
        if (e.target.closest('.floating-chart-header')) {
            setIsDragging(true);
            setDragStart({
                x: e.pageX - position.x,
                y: e.pageY - position.y
            });
        }
    };

    const handleMouseMove = useCallback((e) => {
        if (isDragging) {
            const newX = e.pageX - dragStart.x;
            const newY = e.pageY - dragStart.y;

            // Ensure the chart stays within window bounds
            const maxX = window.innerWidth - 600; // 600px is chart width
            const maxY = window.innerHeight - 400; // 400px is chart height

            setPosition({
                x: Math.min(Math.max(0, newX), maxX),
                y: Math.min(Math.max(0, newY), maxY)
            });
        }
    }, [isDragging, dragStart]);

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, dragStart, handleMouseMove]);

    return (
        <div
            ref={containerRef}
            className={`floating-chart-container ${isMinimized ? 'minimized' : ''}`}
            style={{
                transform: `translate(${position.x}px, ${position.y}px)`,
                position: 'fixed',
                top: 0,
                left: 0
            }}
            onMouseDown={handleMouseDown}
        >
            <div className="floating-chart-header">
                <h3 className="floating-chart-title">{title}</h3>
                <div className="floating-chart-controls">
                    <button
                        className="floating-chart-button"
                        onClick={() => setIsMinimized(!isMinimized)}
                    >
                        {isMinimized ? '□' : '―'}
                    </button>
                </div>
            </div>
            {!isMinimized && (
                <div className="floating-chart-content">
                    {children}
                </div>
            )}
        </div>
    );
};

export default FloatingChart;